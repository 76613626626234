<template lang="pug">
	.d-flex.align-items-center.table-per-page
		span.b3.text-grey.mr-3.text-nowrap {{ from }} {{ $t('h1.of')}} {{ total }}
		dropdown-general(:list="pageSizes" @onChange="size => $emit('onChange', size)")
</template>

<script>
import DropdownGeneral from '@/components/Dropdown/DropdownGeneral';
import { tablePageSizesExtended } from '@/constants/config';

export default {
    name: 'TablePerPage',
    components: {
        DropdownGeneral,
    },
    props: {
        from: {
            type: Number,
            default: 0,
        },
        total: {
            type: [String, Number],
            default: 0,
        },
        pageSizes: {
            type: Array,
            default: () => tablePageSizesExtended,
        },
    },
};
</script>

<style lang="scss">
.table-per-page {
    .dropdown-general {
        height: 31.6px;

        button {
            height: 31.6px;
        }

        .btn {
            padding: 7px 15px !important;
        }
    }
}
</style>
